import { isDefined } from "@clipboard-health/util-ts";
import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { DEPRECATED_DO_NOT_USE_GET_HCP_REQUIREMENTS } from "../../deprecatedDoNotUseGraphQlCH23128";
import { ExpiryType } from "../types";

const getRequirementExpiryRequestSchema = z.object({
  hcpId: z.string().optional(),
  requirementId: z.string().optional(),
});

type GetRequirementExpiryRequest = z.infer<typeof getRequirementExpiryRequestSchema>;

const getRequirementExpiryResponseSchema = z.object({
  data: z.object({
    hcpRequirementList: z
      .object({
        expiry: z.coerce.date(),
        baseRequirement: z.object({
          expiryType: z.nativeEnum(ExpiryType),
        }),
      })
      .array(),
  }),
});

type GetRequirementExpiryResponse = z.infer<typeof getRequirementExpiryResponseSchema>;

export function useGetRequirementExpiry<TData = GetRequirementExpiryResponse>(
  params: GetRequirementExpiryRequest,
  options: UseQueryOptions<GetRequirementExpiryResponse, AxiosError, TData> = {}
): UseQueryResult<TData> {
  const { hcpId, requirementId } = params;
  return useQuery({
    queryKey: [DEPRECATED_DO_NOT_USE_GET_HCP_REQUIREMENTS, params],
    queryFn: async () => {
      const response = await post({
        url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
        data: JSON.stringify({
          query: DEPRECATED_DO_NOT_USE_GET_HCP_REQUIREMENTS,
          variables: {
            hcpId,
            filter: { requirementId },
          },
        }),
        responseSchema: getRequirementExpiryResponseSchema,
      });
      return response.data;
    },
    enabled: isDefined(hcpId) && isDefined(requirementId),
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_REQUIREMENT_EXPIRY_FAILURE,
    },
    ...options,
  });
}
