import { isDefined } from "@clipboard-health/util-ts";
import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { DEPRECATED_DO_NOT_USE_GET_FILTERED_DOCUMENTS } from "../../deprecatedDoNotUseGraphQlCH23128";

const getDocumentRequestParamsSchema = z.object({
  hcpId: z.string().optional(),
  requirementId: z.string().optional(),
});

type GetDocumentRequestParams = z.infer<typeof getDocumentRequestParamsSchema>;

const getDocumentResponseSchema = z.object({
  data: z.object({
    documentList: z
      .object({
        _id: z.string(),
        createdAt: z.coerce.date(),
        subType: z.string().nullable(),
      })
      .array(),
  }),
});

type GetDocumentResponse = z.infer<typeof getDocumentResponseSchema>;

export function useGetDocument<TData = GetDocumentResponse>(
  params: GetDocumentRequestParams,
  options: UseQueryOptions<GetDocumentResponse, AxiosError, TData> = {}
): UseQueryResult<TData> {
  const { hcpId, requirementId } = params;
  return useQuery({
    queryKey: [DEPRECATED_DO_NOT_USE_GET_FILTERED_DOCUMENTS, params],
    queryFn: async () => {
      const response = await post({
        url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
        data: JSON.stringify({
          query: DEPRECATED_DO_NOT_USE_GET_FILTERED_DOCUMENTS,
          variables: {
            hcpId,
            filter: {
              fulfilledRequirementId: requirementId,
            },
            limit: 1,
            sort: { createdAt: -1 },
          },
        }),
        responseSchema: getDocumentResponseSchema,
      });
      return response.data;
    },
    enabled: isDefined(hcpId) && isDefined(requirementId),
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_DOCUMENT_ID_FAILURE,
    },
    ...options,
  });
}
