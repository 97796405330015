import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import axios, { type AxiosError } from "axios";
import { z } from "zod";

import { DEPRECATED_DO_NOT_USE_GET_PRESIGNED_UPLOAD_URL } from "../../deprecatedDoNotUseGraphQlCH23128";
import { type UploadFileData } from "../types";

const uploadFileToFileStorageRequestSchema = z.object({
  requirementId: z.string(),
});

type UploadFileToFileStorageRequest = z.infer<typeof uploadFileToFileStorageRequestSchema> & {
  file: UploadFileData;
};

const uploadFileToFileStorageResponseSchema = z.object({
  preSignedUrl: z.string(),
  fileStorageRootFolder: z.string(),
  fileStorageFileKey: z.string(),
});

const presignedUploadUrlResponseSchema = z.object({
  data: z.object({
    getPresignedUploadUrl: uploadFileToFileStorageResponseSchema,
  }),
});

type UploadFileToFileStorageResponse = z.infer<typeof uploadFileToFileStorageResponseSchema>;

export function useUploadFileToFileStorage(
  options: UseMutationOptions<
    UploadFileToFileStorageResponse,
    AxiosError,
    UploadFileToFileStorageRequest
  > = {}
) {
  return useMutation({
    mutationFn: async ({ file, requirementId }) => {
      const { extension, contentType, blob } = file;

      const response = await post({
        url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
        data: JSON.stringify({
          query: DEPRECATED_DO_NOT_USE_GET_PRESIGNED_UPLOAD_URL,
          variables: {
            reqId: requirementId,
            fileType: extension,
          },
        }),
        responseSchema: presignedUploadUrlResponseSchema,
      });

      const { preSignedUrl, fileStorageFileKey, fileStorageRootFolder } =
        response.data.data.getPresignedUploadUrl;

      await axios.put(preSignedUrl, blob, {
        headers: {
          "Content-Type": contentType,
        },
      });

      return { preSignedUrl, fileStorageFileKey, fileStorageRootFolder };
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PRESIGNED_URL_UPLOAD_FAILURE,
    },
    ...options,
  });
}
