import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { DEPRECATED_DO_NOT_USE_GET_DISTRIBUTION_URL } from "../../deprecatedDoNotUseGraphQlCH23128";

const distributionUrlSchema = z.object({
  distributionUrl: z.string(),
});

const documentDistributionUrlApiResponseSchema = z.object({
  data: z.object({
    getDistributionUrl: distributionUrlSchema,
  }),
});

export type DocumentDistributionUrlResponse = z.infer<typeof distributionUrlSchema>;

export function useDocumentDistributionUrl(
  options: UseMutationOptions<DocumentDistributionUrlResponse, AxiosError, string> = {}
): UseMutationResult<DocumentDistributionUrlResponse, AxiosError, string> {
  return useMutation({
    mutationFn: async (documentId: string) => {
      const response = await post({
        url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
        data: JSON.stringify({
          query: DEPRECATED_DO_NOT_USE_GET_DISTRIBUTION_URL,
          variables: {
            docId: documentId,
          },
        }),
        responseSchema: documentDistributionUrlApiResponseSchema,
      });
      return response.data.data.getDistributionUrl;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_DISTRIBUTION_URL_FAILURE,
    },
    ...options,
  });
}
