import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

import { type ProfessionalReferenceResponse, professionalReferenceResponseSchema } from "../types";

const PROFESSIONAL_REFERENCE_API_URL = `${environmentConfig.REACT_APP_WORKER_SERVICE_URL}/references`;
export function useGetProfessionalReference(
  options: UseQueryOptions<ProfessionalReferenceResponse>
): UseQueryResult<ProfessionalReferenceResponse> {
  return useQuery({
    queryKey: [PROFESSIONAL_REFERENCE_API_URL],
    queryFn: async () => {
      const response = await get({
        url: PROFESSIONAL_REFERENCE_API_URL,
        responseSchema: professionalReferenceResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_PROFESSIONAL_REFERENCES_FAILURE,
    },
    ...options,
  });
}
