import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { DEPRECATED_DO_NOT_USE_GRAPHQL_DELETE_HCP_DOCUMENT } from "../../deprecatedDoNotUseGraphQlCH23128";

const deleteDocumentResponseSchema = z.object({
  data: z.object({
    deleteHcpDocument: z.object({
      _id: z.string(),
    }),
  }),
});

type DeleteDocumentResponse = z.infer<typeof deleteDocumentResponseSchema>;

export function useDeleteDocument(
  options: UseMutationOptions<DeleteDocumentResponse, AxiosError, string> = {}
) {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await post({
        url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
        data: JSON.stringify({
          query: DEPRECATED_DO_NOT_USE_GRAPHQL_DELETE_HCP_DOCUMENT,
          variables: {
            _id: id,
          },
        }),
        responseSchema: deleteDocumentResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.DELETE_DOCUMENT_FAILURE,
    },
    ...options,
  });
}
