import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const createStripeVerificationSessionRequestSchema = z.object({
  hcpId: z.string(),
  documentId: z.string(),
});

type CreateStripeVerificationSessionRequest = z.infer<
  typeof createStripeVerificationSessionRequestSchema
>;

export const createStripeVerificationSessionResponseSchema = z.object({
  id: z.string(),
  workerUserId: z.string(),
  clientSecret: z.string(),
  verificationSessionId: z.string(),
  documentId: z.string(),
  ephemeralKeySecret: z.string().optional(),
});

export type CreateStripeVerificationSessionResponse = z.infer<
  typeof createStripeVerificationSessionResponseSchema
>;

export function useCreateStripeVerificationSession(
  options: UseMutationOptions<
    CreateStripeVerificationSessionResponse,
    AxiosError,
    CreateStripeVerificationSessionRequest
  > = {}
) {
  return useMutation({
    mutationFn: async ({ hcpId, documentId }) => {
      const response = await post({
        url: `${environmentConfig.REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL}/v1/workers/${hcpId}/stripe/verification-sessions`,
        data: { documentId },
        responseSchema: createStripeVerificationSessionResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_STRIPE_VERIFICATION_SESSION_FAILURE,
    },
    ...options,
  });
}
