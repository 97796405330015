import { isDefined } from "@clipboard-health/util-ts";
import { MuiMarkdown } from "@src/appV2/lib";

import { RequirementStatusAlert } from "../components/RequirementStatus";
import { useDocumentDetailsContext } from "../useDocumentDetails";
import { getRequirementDetailsContentCopies } from "../utils/getRequirementDetailsContentCopies";

export function DocumentDetailsRequirementStatus() {
  const { selectedRequirement, documentId, alertRequirementStatus, rejectedDocumentNotification } =
    useDocumentDetailsContext();

  if (
    !isDefined(alertRequirementStatus) ||
    !isDefined(selectedRequirement) ||
    !isDefined(documentId)
  ) {
    return null;
  }

  const requirementDetailsContentCopies = getRequirementDetailsContentCopies({
    hcpRequirement: selectedRequirement,
    rejectedDocumentNotification,
    requirementStatus: alertRequirementStatus,
  });

  return (
    <RequirementStatusAlert
      title={requirementDetailsContentCopies?.title ?? ""}
      status={alertRequirementStatus}
    >
      <MuiMarkdown variant="h3">{requirementDetailsContentCopies?.description ?? ""}</MuiMarkdown>
    </RequirementStatusAlert>
  );
}
