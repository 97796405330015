import { Image, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Card, CardContent, IconButton, Stack } from "@mui/material";

import { useDocumentUploadContext } from "../hooks";
import { FileSelectionType } from "../types";
import { useDocumentDetailsContext } from "../useDocumentDetails";

export function SelectedFileDisplay() {
  const { isStripeVerificationFlow } = useDocumentDetailsContext();
  const { selectedFiles, fileSelectionType, removeSelectedFile } = useDocumentUploadContext();

  if (!selectedFiles || !isDefined(fileSelectionType) || isStripeVerificationFlow) {
    return null;
  }

  return (
    <Stack spacing={1}>
      {selectedFiles.map((selectedFile, index) => {
        return (
          <Card key={selectedFile.dataUrlBase64} variant="outlined">
            <CardContent>
              <IconButton
                onClick={async () => {
                  await removeSelectedFile(index);
                }}
              >
                <CloseIcon />
              </IconButton>
              {fileSelectionType === FileSelectionType.PDF ? (
                <Stack spacing={1} padding={2} sx={{ width: "100%", alignItems: "center" }}>
                  <PictureAsPdfIcon />
                  <Text variant="h3">Document</Text>
                </Stack>
              ) : (
                <Image src={selectedFile.dataUrlBase64} alt={`uploaded-image-${index}`} />
              )}
            </CardContent>
          </Card>
        );
      })}
    </Stack>
  );
}
