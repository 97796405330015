import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { DEPRECATED_DO_NOT_USE_GRAPHQL_CREATE_HCP_DOCUMENT } from "../../deprecatedDoNotUseGraphQlCH23128";

const createDocumentRequestSchema = z.object({
  subType: z.string(),
  hcpId: z.string(),
  name: z.string(),
  uploadedFor: z.string(),
  fileStorageRootFolder: z.string(),
  fileStorageFileKey: z.string(),
});

type CreateDocumentRequest = z.infer<typeof createDocumentRequestSchema>;

const createDocumentResponseSchema = z.object({
  data: z.object({
    createHcpDocument: z.object({
      _id: z.string(),
      name: z.string(),
    }),
  }),
});

type CreateDocumentResponse = z.infer<typeof createDocumentResponseSchema>;

export function useCreateDocument(
  options: UseMutationOptions<CreateDocumentResponse, AxiosError, CreateDocumentRequest> = {}
): UseMutationResult<CreateDocumentResponse, AxiosError, CreateDocumentRequest> {
  return useMutation({
    mutationFn: async (variables: CreateDocumentRequest) => {
      const response = await post({
        url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
        data: JSON.stringify({
          query: DEPRECATED_DO_NOT_USE_GRAPHQL_CREATE_HCP_DOCUMENT,
          variables,
        }),
        responseSchema: createDocumentResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_DOCUMENT_FAILURE,
    },
    ...options,
  });
}
