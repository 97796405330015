import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { DEPRECATED_DO_NOT_USE_GET_REJECTED_DOCUMENT } from "../../deprecatedDoNotUseGraphQlCH23128";

const getRejectedDocumentRequestSchema = z.object({
  hcpId: z.string().optional(),
  requirementId: z.string().optional(),
});

export type GetRejectedDocumentRequestSchema = z.infer<typeof getRejectedDocumentRequestSchema>;

const rejectedDocumentNotificationSchema = z.object({
  reason: z.string().optional().nullable(),
  note: z.string().optional().nullable(),
  pushNotification: z.boolean().optional().nullable(),
});

export type RejectedDocumentNotification = z.infer<typeof rejectedDocumentNotificationSchema>;

export const getRejectedDocumentApiResponseSchema = z.object({
  data: z.object({
    rejectedDocument: z
      .object({
        notification: rejectedDocumentNotificationSchema,
      })
      .nullable(),
  }),
});

type GetRejectedDocumentResponseSchema = z.infer<typeof getRejectedDocumentApiResponseSchema>;

export function useGetRejectedDocumentNotification<TData = GetRejectedDocumentResponseSchema>(
  params: GetRejectedDocumentRequestSchema,
  options: UseQueryOptions<GetRejectedDocumentResponseSchema, AxiosError, TData> = {}
): UseQueryResult<TData> {
  const { hcpId, requirementId } = params;
  return useQuery({
    queryKey: [DEPRECATED_DO_NOT_USE_GET_REJECTED_DOCUMENT, params],
    queryFn: async () => {
      const response = await post({
        url: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
        data: JSON.stringify({
          query: DEPRECATED_DO_NOT_USE_GET_REJECTED_DOCUMENT,
          variables: { hcpId, requirementId },
        }),
        responseSchema: getRejectedDocumentApiResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_REJECTED_DOCUMENT_NOTIFICATION_FAILURE,
    },
    ...options,
  });
}
